export const trackingTypes = {
  callMeNow: 'CallMeNow',
  quoteRequest: 'QuoteRequest',
  infoPackRequest: 'InfoPackRequest',
  onlineQuote: 'OnlineQuote',
  willKit: 'WillKit',
  leadByte: 'QuoteRequest',
  funnel: 'Funnel',
  abandoned: 'Abandoned',
  opened: 'Opened',
  submitted: 'Submitted'
}

export const e164NumCodes = {
  AU: '+61',
  NZ: '+64',
  GB: '+44',
  US: '+1',
  CA: '+1'
}
