import { ref, computed, watch, nextTick } from 'vue'
import * as yup from 'yup'
import mapValues from 'lodash/mapValues'
import { getAge } from '#root/utils/helpers/helperDateTime.js'
import { reduceToObject as reduce, filter } from '#root/utils/helpers/helperData.js'
import { phonePattern } from '#config'
import { Model } from '#root/utils/models/Date.js'
import { useTrackingStore } from '#root/stores/storeTracking.js'
import { getValidatePhone } from '#root/api/telephony.js'
import { getValidateEmailDetailed } from '#root/api/customers.js'
import { useGetContentPage } from '#root/components/composables/getContent.js'

export class InvalidEmailError extends Error {
  constructor(suggestion = null) {
    super('Invalid email')

    this.type = suggestion ? 'misspelled' : 'checkFailed'
    this.suggestion = suggestion
  }
}

const mergeFieldSettings = (overrides, base) =>
  mapValues(base, (settings, field) => {
    if (field in overrides) {
      const overrideSettings = overrides[field]

      if (typeof overrideSettings === 'object' && overrideSettings !== null) {
        return { ...settings, ...overrideSettings }
      } else {
        return overrideSettings
      }
    } else {
      return settings
    }
  })

// Used for input element
const getNamePattern = () => `^[a-zA-Z'\\s\\-]+$`

const getElements = (data) => (data?.elements ? data.elements : data)

export class InvalidFormSubmission extends Error {}
export class UnvalidatedFormSubmission extends Error {}

export const useForm = (options = {}) => {
  const {
    schemas,
    initialValues = {},
    areEqual = (a, b) => a === b,
    mapError = () => 'Field error'
  } = options

  const fields = Object.keys(schemas)
  const totalValidations = ref(0)
  const errors = ref(reduce(fields, () => [null]))
  const rawErrors = ref(reduce(fields, () => [null]))

  // used to make exception to validate on blur (which freezes submit button)
  // when clicking submit button (as this also triggers blur)
  const validationBlocked = ref(false)

  const setError = (field, error) => {
    if (error) {
      errors.value[field] = mapError(error, field)
      rawErrors.value[field] = error
    } else {
      errors.value[field] = null
      rawErrors.value[field] = null
    }
  }

  const toggleValidationBlock = (status = !validationBlocked.value) => {
    validationBlocked.value = status
  }

  const validateField = async (field, force = false) => {
    const doTest = (test, value) => {
      if (typeof test === 'function') {
        return test(value, field)
      } else {
        return test.validate(value, { strict: true })
      }
    }

    const { value } = binds[field]
    if (validationBlocked.value) {
      return
    }

    totalValidations.value += 1
    validations.value[field] += 1

    validatedValues.value[field] = null
    setError(field, null, null)

    try {
      if (!force) {
        const tests = schemas[field]

        if (Array.isArray(tests)) {
          for (const test of tests) {
            await doTest(test, value)
          }
        } else {
          await doTest(tests, value) // must be a Yup schema or function
        }
      }

      if (areEqual(value, binds[field].value, field)) {
        validatedValues.value[field] = { value }
        setError(field, null, null)
      }

      return { valid: true, value }
    } catch (e) {
      if (areEqual(value, binds[field].value, field)) {
        validatedValues.value[field] = null
        setError(field, e)
      }

      return { valid: false, value }
    } finally {
      totalValidations.value -= 1
      validations.value[field] -= 1
    }
  }

  const submittedValues = ref(null)

  const validateFields = async (failOnError = false) => {
    const validations = []

    if (failOnError) {
      for (const field of fields) {
        if (isFailed.value[field]) {
          throw new Error('Cannot validate with known errors')
        }
      }
    }

    for (const field of fields) {
      if (!isPassed.value[field] && !isFailed.value[field]) {
        validations.push(validateField(field))
      }
    }

    await Promise.all(validations)
  }

  // Initialize object as { fieldName: 0 }
  const validations = ref(reduce(fields, () => [0]))

  // Translate validations counter to simple boolean
  const isFieldValidating = computed(() => mapValues(validations.value, (validatingNow) => validatingNow > 0))

  const isFormLoading = computed(() => totalValidations.value > 0)

  const formSubmitted = computed(() => submittedValues.value !== null)

  const isFormBtnDisabled = ref(false)

  const isPassed = computed(() =>
    mapValues(validatedValues.value, (result, field) => {
      if (result !== null) {
        const { value } = binds[field]

        return areEqual(result.value, value, field)
      } else {
        return false
      }
    })
  )

  const isFailed = computed(() => mapValues(errors.value, (result) => result !== null))

  // has current field value either passed or failed validation
  const isTested = computed(() => reduce(fields, (field) => [isFailed.value[field] || isPassed.value[field]]))

  const validatedValues = ref(reduce(fields, () => [null]))
  const formSubmissions = ref(0)

  const isFormInvalid = computed(() => {
    for (const field of fields) {
      if (isFailed.value[field]) {
        return true
      }
    }

    return false
  })

  const isFormValid = computed(() => {
    for (const field of fields) {
      if (!isPassed.value[field]) {
        return false
      }
    }

    return true
  })

  const submitForm = async (fn) => {
    if (isFormInvalid.value) {
      throw new InvalidFormSubmission('Form has invalid values')
    }

    if (isFormValid.value) {
      const values = mapValues(binds, (ref) => ref.value)

      formSubmissions.value += 1

      try {
        return await Promise.resolve(fn(values))
      } finally {
        formSubmissions.value -= 1
      }
    } else {
      throw new UnvalidatedFormSubmission('Form is not ready for submission')
    }
  }

  const isFormSubmitting = computed(() => formSubmissions.value > 0)

  const binds = reduce(fields, (field) => {
    const bind = ref(initialValues[field])

    // reset errors and passed status when value changes
    watch(bind, () => {
      setError(field, null)
      validatedValues.value[field] = null
    })

    return [bind]
  })

  return {
    fields: binds,
    submittedValues,
    formSubmitted,
    isFormBtnDisabled,
    isFormLoading,
    isFormValid,
    isFormInvalid,
    isFormSubmitting,
    isFieldValidating,
    isPassed,
    isFailed,
    isTested,
    errors,
    rawErrors,
    validationBlocked,
    submitForm,
    validateField,
    validateFields,
    toggleValidationBlock
  }
}

/**
 * Description placeholder
 * @date 9/28/2023 - 12:32:22 PM
 *
 * @param {{}} [options={}]
 * @satisfies {
 *  fields?: []<string> (subset of schema fields to include)
 *  initialValues?: {<string>: <any>} (custom initial values for each field)
 *  schemaSettings?: {
 *    phone?: { pattern?: <string> (phone regexp to enforce format) }
 *    dob?: { minAge?: <int> (min age to enforce), maxAge?: (max age to enforce) }
 *  },
 *  errorMessages?: {
 *    <string>:
 *      <string> (represents error message) |
 *      {<string|'default'>: <string> (error message for given error or default error message)}
 *  }
 * }
 * @satisfies fields is array of any of
 *  'firstName'
 *  'lastName'
 *  'phone',
 *  'year'
 *  'month'
 *  'day'
 *  'email'
 *  'dob'
 *  'postCode'
 *  'provinceId',
 *  'coverGroup',
 *  'coverType',
 *  'ageCheck',
 *  'ageCheckRange',
 *  'coverAmount',
 *  'smoker',
 *  'gender',
 *  'leaveSum',
 *  'confirmResidency'
 * @returns defined field refs and functions from `useForm`
 */
export const useGeneralForm = (options = {}, disableEmailValidation = false) => {
  const {
    initialValues = {},
    fields = [
      'ageCheck',
      'firstName',
      'lastName',
      'dob',
      'phone',
      'email',
      'coverGroup',
      'gender',
      'coverType',
      'coverAmount',
      'smoker',
      'provinceId',
      'ageCheckRange',
      'leaveSum',
      'confirmResidency'
    ]
  } = options

  const schemaSettings = mergeFieldSettings(options.schemaSettings ?? {}, {
    phone: { pattern: phonePattern },
    dob: { minAge: 18, maxAge: Infinity }
  })

  const errorMessages = mergeFieldSettings(options.errorMessages ?? {}, {
    ageCheck: { default: 'Please confirm your age' },
    ageCheckRange: { default: 'Please confirm your age' },
    firstName: { default: 'First name is invalid' },
    lastName: { default: 'Last name is invalid' },
    postCode: { default: 'Postal Code is not valid' },
    provinceId: { default: 'Please select your state' }, // or region (Locale)
    email: {
      default: 'Please enter your email address in format: <strong>name@domain.com</strong>',
      misspelled: 'Did you mean <b>[[CORRECTED]]</b> Click to accept.',
      checkFailed: 'Email is not valid'
    },
    phone: {
      default: 'Your phone number must be a valid contact number',
      checkFailed: 'Please enter a valid phone number'
    },
    dob: {
      dateInvalid: 'Date of birth is not a valid date',
      dobInvalid: 'You must be of the right age',
      default: 'Please complete your date of birth'
    }
  })

  const yearSchema = yup
    .string()
    .required()
    .matches(/^[0-9]{4}$/)
  const monthSchema = yup
    .string()
    .required()
    .matches(/^[0-9]+$/)
  const daySchema = yup
    .string()
    .required()
    .matches(/^[0-9]+$/)

  const { trackFormFieldInteraction, trackFormError } = useTrackingStore()

  // Get the form type from the page content
  const pageContent = useGetContentPage('page_blocks.linkedItems', 'default')
  const findValueByKey = (objArray, key) => {
    for (let obj of objArray) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return obj[key]
      } else {
        for (let nestedKey in obj) {
          if (typeof obj[nestedKey] === 'object') {
            let nestedResult = findValueByKey([obj[nestedKey]], key)
            if (nestedResult) {
              return nestedResult
            }
          }
        }
      }
    }
    return null
  }

  // Arrays must be used to control validation sequence
  const schemas = {
    ageCheck: yup.boolean().required().oneOf([true]),
    ageCheckRange: yup.string().required().notOneOf(['No', 'Neither']),
    firstName: yup.string().required().max(50),
    lastName: yup.string().required().max(50),
    day: daySchema,
    month: monthSchema,
    year: yearSchema,
    dob: [
      yup
        .object({
          year: yearSchema,
          month: monthSchema,
          day: daySchema
        })
        .required(),
      yup.object().test('dateInvalid', (dob) => {
        const date = dob.toNumeric()

        return date.isValid()
      }),
      yup.object().test('dobInvalid', (model) => {
        const tryDate = model.toNumeric()
        const age = getAge(tryDate.toPostString())
        const { minAge, maxAge } = schemaSettings.dob

        return !!(age >= (minAge ?? 0) && age <= (maxAge ?? Infinity))
      })
    ],
    phone: [
      yup.string().required().matches(new RegExp(schemaSettings.phone.pattern)),
      yup.string().test('checkFailed', (phoneNumber) =>
        getValidatePhone({ phoneNumber }).catch((e) => {
          let errorLog = ''
          if (e instanceof Error) {
            errorLog = e.message
          } else {
            errorLog = e ?? 'Unknown Phone Error'
          }
          const formType = findValueByKey(pageContent, 'form_type').value[0].name ?? 'Unknown Form Type'
          trackFormError('Server Error', 'Validate Phone error: ' + errorLog, formType)
          return true
        })
      )
    ],
    // https://github.com/jquense/yup/issues/507
    email: disableEmailValidation
      ? [
          yup
            .string()
            .required()
            .matches(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ]
      : [
          yup
            .string()
            .required()
            .matches(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ),
          (email) =>
            getValidateEmailDetailed({ email })
              .catch((e) => {
                let errorLog = ''
                if (e instanceof Error) {
                  errorLog = e.message
                } else {
                  errorLog = e ?? 'Unknown Email Error'
                }
                const formType = findValueByKey(pageContent, 'form_type').value[0].name ?? 'Unknown Form Type'
                trackFormError('Server Error', 'Validate Email error: ' + errorLog, formType)
              })
              .then((result) => {
                if (!result.isValid) {
                  throw new InvalidEmailError(result.emailSuggestion || null)
                }
              })
        ],
    postCode: yup
      .string()
      .required()
      .max(20)
      .matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]/),
    provinceId: yup.number().required(),
    coverGroup: yup.string().required(),
    gender: yup.string().required(),
    coverType: yup.string().required(),
    coverAmount: yup.string().required(),
    leaveSum: yup.string().required(),
    confirmResidency: yup.string().required(),
    smoker: yup.string().required()
  }

  const areEqual = (a, b, field) => {
    switch (field) {
      case 'dob':
        return a.hash() === b.hash()
      default:
        return a === b
    }
  }

  const createFieldValidator = (field, track = true) => {
    return async (event) => {
      if (fields.includes(field)) {
        if (track) trackFormFieldInteraction(event)

        await form.validateField(field)
      } else {
        throw new Error(`"${field}" is not part of this form`)
      }
    }
  }

  const mapError = (error, field) => {
    const mapping = errorMessages[field]

    if (typeof mapping === 'string') {
      return mapping
    }

    if (error.type in mapping) return mapping[error.type]
    if ('default' in mapping) return mapping.default
    else return error.message
  }

  const form = useForm({
    schemas: reduce(fields, (field) => [schemas[field]]),
    initialValues: {
      // by default all fields are initialized to empty strings
      ...reduce(fields, () => ['']),
      ageCheck: null,
      ageCheckRange: null,
      dob: new Model(), // make any exceptions here...
      ...(initialValues ?? {})
    },
    mapError,
    areEqual
  })

  const {
    fields: {
      ageCheck = null,
      firstName = null,
      lastName = null,
      phone = null,
      email = null,
      day = null,
      month = null,
      year = null,
      dob = null,
      postCode = null,
      provinceId = null,
      coverGroup = null,
      ageCheckRange = null,
      gender = null,
      coverAmount = null,
      coverType = null,
      smoker = null,
      leaveSum = null,
      confirmResidency = null
    }
  } = form

  const validateAgeCheck = createFieldValidator('ageCheck')
  const validateAgeCheckRange = createFieldValidator('ageCheckRange')
  const validateEmail = createFieldValidator('email')
  const validatePhone = createFieldValidator('phone')
  const validatePostCode = createFieldValidator('postCode')
  const validateDOB = createFieldValidator('dob', false)
  const validateProvinceId = createFieldValidator('provinceId')

  const nonTrackableFields = new Set(['dob'])
  const hasField = reduce(Object.keys(schemas), (field) => [fields.includes(field)])

  const validationProps = computed(() => {
    return reduce(fields, (field) => [
      {
        bind: {
          isValidating: form.isFieldValidating.value[field],
          isValidated: form.isPassed.value[field],
          error: form.errors.value[field],
          errorFix: form.rawErrors.value[field]?.suggestion ?? null
        },
        on: {
          blur: (event = null) => {
            if (!nonTrackableFields.has(field) && event !== null) {
              trackFormFieldInteraction(event)
            }
            // don't validate if we already know whether field is valid
            if (!form.isTested.value[field]) {
              form.validateField(field)
            }

            if (field === 'provinceId') {
              form.validateField(field)
            }
          },
          validate: (event) => {
            // Don't validate if field's value has changed
            nextTick(() => {
              if (event.value === form.fields[field].value) {
                form.validateField(field, event.force)
              }
            })
          }
        }
      }
    ])
  })

  return {
    ageCheck,
    firstName,
    lastName,
    dob,
    day,
    month,
    year,
    phone,
    email,
    postCode,
    provinceId,
    coverGroup,
    ageCheckRange,
    coverType,
    coverAmount,
    smoker,
    gender,
    leaveSum,
    confirmResidency,
    hasField,
    validateAgeCheck,
    validateAgeCheckRange,
    validationProps,
    validateEmail,
    validatePhone,
    validatePostCode,
    validateDOB,
    validateProvinceId,
    ...form
  }
}

export const getGenericParams = (data) => {
  const elements = getElements(data)
  const form =
    +elements?.form_validations?.linkedItems?.length > 0
      ? elements.form_validations.linkedItems[0].elements
      : null

  const errorMessages =
    form === null
      ? {}
      : {
          firstName: filter({
            default: form.validations_first_name?.value
          }),
          lastName: filter({
            default: form.validations_last_name?.value
          }),
          email: filter({
            default: boldEmailText(form.validations_email_default?.value),
            checkFailed: boldEmailText(form.validations_email_fail?.value)
          }),
          phone: filter({
            default: form.validations_phone_default?.value,
            checkFailed: form.validations_phone_fail?.value
          }),
          dob: filter({
            default: form.validations_dob_default?.value,
            dateInvalid: form.validations_date_invalid?.value,
            dobInvalid: form.validations_dob_invalid?.value.replace(/MINAGE|MAXAGE/gi, (match) => {
              switch (match) {
                case 'MINAGE':
                  return form.validations_min_age?.value
                case 'MAXAGE':
                  return form.validations_max_age?.value
                default:
                  return match
              }
            })
          }),
          ageCheck: filter({
            default: form.validations_age_checkbox?.value
          }),
          postCode: filter({
            default: form.validations_post_code_default?.value
          })
        }

  const schemaSettings = {
    phone: {
      pattern: phonePattern
    },
    dob: filter({
      minAge: form?.validations_min_age?.value,
      maxAge: form?.validations_max_age?.value
    })
  }

  const fields =
    elements.form_steps?.linkedItems?.length > 0
      ? getMultiStepFields(elements.form_steps.linkedItems)
      : getGenericFields(elements)

  return { fields, schemaSettings, errorMessages }
}

const boldEmailText = (copy) => {
  // bold everything after the : in the string
  const [prefix, suffix] = copy.split(':')
  return `${prefix}: <strong>${suffix}</strong>`
}

const getMultiStepFields = (steps) => {
  const fields = []
  for (const step of steps) {
    fields.push(...getStepFields(step.system?.type))
  }

  return fields
}

const getGenericFields = (elements) => {
  return Object.keys(
    filter(
      {
        firstName: ['first_name_label'],
        lastName: ['last_name_label'],
        phone: ['phone_number_label'],
        email: ['email_label'],
        postCode: ['postcode_label'],
        provinceId: ['region_select_label'],
        ageCheck: ['age_requirement'],
        ageCheckRange: ['age_requirement'],
        dob: ['date_of_birth_label'] || [('date_of_birth_labels', (value) => value.length > 0)]
      },
      ([prop, fn = (value) => !!value]) => !!(elements[prop]?.value && fn(elements[prop]?.value))
    )
  )
}

export const getDOBLabels = (data) => {
  const elements = getElements(data)

  return !elements.date_of_birth_labels?.value
    ? {}
    : reduce(elements.date_of_birth_labels.value, (label) => [label.codename, label.name])
}

export const getCheckPattern = (event, pattern) => {
  if (pattern) {
    const regex = new RegExp(pattern)
    if (!regex.test(event.key)) {
      event.preventDefault()
    }
  }
}

export const getStepFields = (type) => {
  switch (type) {
    case 'form_step___fname_lname':
      return ['firstName', 'lastName']
    case 'form_step___fname_lname__agecheck':
      return ['firstName', 'lastName', 'ageCheck']
    case 'form_step___dob':
      return ['dob']
    case 'form_step___phone_email':
      return ['phone', 'email']
    case 'form_step___name_dob':
      return ['firstName', 'lastName', 'dob']
    case 'form_step___gender':
      return ['gender']
    case 'form_step___cover_group':
      return ['coverGroup']
    case 'form_step___cover_amount':
      return ['coverAmount']
    case 'form_step___cover_type':
      return ['coverType']
    case 'form_step___smoker_status':
      return ['smoker']
    case 'form_step___birthdate':
      return ['dob']
    case 'form_step___regions':
      return ['provinceId']
    case 'form_step___age_check_range':
      return ['ageCheckRange']
    case 'form_step___confirm_residency':
      return ['confirmResidency']
    case 'form_step___leave_sum':
      return ['leaveSum']
    default:
      return []
  }
}

export const inputPatterns = (locale = null) => {
  return {
    name: getNamePattern()
  }
}

export const regionCodenameToNum = (regions) => {
  return regions.map((region) => ({
    ...region,
    codename: parseInt(region.codename.replace(/^_/, ''), 10)
  }))
}
