import lodashGet from 'lodash/get'
import { usePagesStore } from '#root/stores/storePages.js'
import { useGlobalStore } from '#root/stores/storeGlobal.js'

const emptyRichText = '<p><br></p>'

export const useGetContent = (object, path, defaultValue = '') => {
  const content = lodashGet(object?.elements ?? object, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentItemId = (path, defaultValue = '') => {
  const content = lodashGet(object?.system.id ?? object, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentHeader = (path, defaultValue = '') => {
  const pageStore = usePagesStore()
  const content = lodashGet(pageStore.header, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentFooter = (path, defaultValue = '') => {
  const pageStore = usePagesStore()
  const content = lodashGet(pageStore.footer, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentSidebar = (path, defaultValue = '') => {
  const pageStore = usePagesStore()
  const content = lodashGet(pageStore.sidebar, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentPage = (path, defaultValue = '') => {
  const pageStore = usePagesStore()
  const content = lodashGet(pageStore.pageContent?.elements, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentConfig = (path, defaultValue = '') => {
  const pageStore = usePagesStore()
  const content = lodashGet(pageStore.contentConfig, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentOptions = (path, defaultValue = '') => {
  const pageStore = useGlobalStore()
  const content = lodashGet(pageStore.options?.elements, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentCookieOptions = (path, defaultValue = '') => {
  const pageStore = usePagesStore()
  const content = lodashGet(pageStore.contentCookieOptions?.elements, path, defaultValue)
  return cleanRichText(content)
}

export const useGetContentGTMOptions = (value, defaultValue = '') => {
  const pageStore = useGlobalStore()
  const content =
    pageStore.options?.elements?.gtm_attributes.value.find((a) => a.codename === value)?.name || defaultValue
  return cleanRichText(content)
}

export const useGetUrl = (object) => {
  let content = {}

  if (object.system.type === 'navigation_item_model') {
    content = {
      url: object.elements.url?.value || null,
      title: object.elements.title?.value || null,
      target: object.elements.open_in_new_tab?.value?.codename === 'yes' ? '_blank' : false
    }
  } else if (object.system.type === 'link_model') {
    content = {
      url:
        object.elements.site_link?.linkedItems[0]?.elements.url.value ||
        object.elements.external_link?.value ||
        null,
      title: object.elements.link_text?.value || null,
      target: object.elements.open_in_new_tab?.value?.codename === 'yes' ? '_blank' : false
    }
  }
  return content
}

export const useDetermineRel = (nav, nofollowProps) => {
  const noIndexOption = useGetContent(nav, 'no_index_no_follow.value[0].name', '')
  const nofollowOption = useGetContent(nav, 'no_index_no_follow.value[1].name', '')
  let relValues = []
  if (nofollowProps) relValues.push(nofollowProps)
  if (nofollowOption && !nofollowProps) relValues.push(nofollowOption)
  if (noIndexOption) relValues.push(noIndexOption)
  return relValues.join(' ') || undefined
}

export const useCleanText = (content) => {
  return content === emptyRichText ? '' : content
}

const cleanRichText = (content) => {
  return content === emptyRichText ? '' : content
}
