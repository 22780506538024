import { computed, watch } from 'vue'
import { defineStore } from 'pinia'
import { useSetter } from '#root/utils/helpers/helperRef.js'
import { useGetContentOptions } from '#root/components/composables/getContent.js'

export const usePagesStore = defineStore('pages', () => {
  const [pageContent, setPageContent] = useSetter(null)
  const [header, setHeader] = useSetter(null)
  const [footer, setFooter] = useSetter(null)
  const [sidebar, setSidebar] = useSetter(null)
  const [campaigns, setCampaigns] = useSetter([])
  const [breadcrumbs, setBreadcrumbs] = useSetter([])
  const [notFound, setNotFound] = useSetter(false)
  const [template, setTemplate] = useSetter('')

  const head = computed(() => {
    return {
      title: pageContent.value?.elements?.seo_metadata__meta_title?.value || '',
      description: pageContent.value?.elements?.seo_metadata__meta_description?.value || '',
      canonical: pageContent.value?.elements?.seo_metadata__canonical_url?.value || '',
      alternateUrl: pageContent.value?.elements?.seo_metadata__alternate_url?.value || null,
      alternateTitle: pageContent.value?.elements?.seo_metadata__alternate_title?.value || null,
      alternateLang: pageContent.value?.elements?.seo_metadata__alternate_hreflang?.value || null,
      insightAppSecValidation: useGetContentOptions('insight_validation.value', ''),
      noIndex: pageContent.value?.elements?.seo_metadata__noindex?.value.length ? true : false,
      og: {
        title: pageContent.value?.elements?.seo_opengraph__og_title?.value || '',
        description: pageContent.value?.elements?.seo_opengraph__og_description?.value || '',
        url: pageContent.value?.elements?.seo_opengraph__og_url?.value || '',
        image: pageContent.value?.elements?.seo_opengraph__og_image?.value || '',
        video: pageContent.value?.elements?.seo_opengraph__og_video?.value || '',
        locale: pageContent.value?.elements?.seo_opengraph__og_locale?.value || '',
        type: pageContent.value?.elements?.seo_opengraph__og_type?.value || '',
        siteName: pageContent.value?.elements?.seo_opengraph__og_site_name?.value || ''
      }
    }
  })

  watch(pageContent, (state) => {
    if (pageContent.value?.system?.type === 'article_template_model') {
      setTemplate('Article')
    } else if (sidebar.value?.system?.type === 'styleguide_sidebar_global_model') {
      setTemplate('Styleguide')
    } else if (pageContent.value?.system?.type === 'landing_page_template_model') {
      if (!!pageContent.value?.elements?.use_funnel_template?.value[0]) {
        setTemplate('Funnel')
      } else {
        setTemplate('Page')
      }
    } else {
      setTemplate('Page')
    }
  })

  return {
    header,
    setHeader,
    footer,
    campaigns,
    pageContent,
    breadcrumbs,
    notFound,
    setFooter,
    sidebar,
    setSidebar,
    setPageContent,
    setBreadcrumbs,
    setNotFound,
    setCampaigns,
    head,
    template
  }
})
